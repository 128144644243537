/* =============
   Calendar
============= */
/* =============
   Checkbox and Radios
============= */
.checkbox label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: normal;
}
.checkbox label::before {
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 3px;
  border: 2px solid #98a6ad;
  content: "";
  display: inline-block;
  height: 18px;
  left: 0;
  margin-left: -18px;
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 18px;
  outline: none !important;
}
.checkbox label::after {
  color: #797979;
  display: inline-block;
  font-size: 11px;
  height: 18px;
  left: 0;
  margin-left: -18px;
  padding-left: 3px;
  padding-top: 2px;
  position: absolute;
  top: 0;
  width: 18px;
}
.checkbox input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.checkbox input[type=checkbox]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox]:focus + label::before {
  outline-offset: -2px;
  outline: none;
}
.checkbox input[type=checkbox]:checked + label::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 7px;
  display: table;
  width: 5px;
  height: 10px;
  border: 1px solid #313a46;
  border-top-width: 0;
  border-left-width: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkbox input[type=checkbox]:disabled + label::before {
  background-color: #e3eaef;
  cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
  border-radius: 50%;
}

.checkbox.checkbox-inline {
  margin-top: 0;
}

.checkbox.checkbox-single input {
  height: 18px;
  width: 18px;
  position: absolute;
}
.checkbox.checkbox-single label {
  height: 18px;
  width: 18px;
}
.checkbox.checkbox-single label:before {
  margin-left: 0;
}
.checkbox.checkbox-single label:after {
  margin-left: 0;
}

.checkbox-custom input[type=checkbox]:checked + label::before {
  background-color: #02c0ce;
  border-color: #02c0ce;
}
.checkbox-custom input[type=checkbox]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-primary input[type=checkbox]:checked + label::before {
  background-color: #02c0ce;
  border-color: #02c0ce;
}
.checkbox-primary input[type=checkbox]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-danger input[type=checkbox]:checked + label::before {
  background-color: #f1556c;
  border-color: #f1556c;
}
.checkbox-danger input[type=checkbox]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-info input[type=checkbox]:checked + label::before {
  background-color: #4eb7eb;
  border-color: #4eb7eb;
}
.checkbox-info input[type=checkbox]:checked + label::after {
  border-color: #ffffff;
}

.checkbox-warning input[type=checkbox]:checked + label::before {
  background-color: #f9bc0b;
  border-color: #f9bc0b;
}
.checkbox-warning input[type=checkbox]:checked + label::after {
  border-color: #ffffff;
}

/* Radios */
.radio label {
  display: inline-block;
  padding-left: 8px;
  position: relative;
  font-weight: normal;
}
.radio label::before {
  -o-transition: border 0.5s ease-in-out;
  -webkit-transition: border 0.5s ease-in-out;
  background-color: #ffffff;
  border-radius: 50%;
  border: 2px solid #98a6ad;
  content: "";
  display: inline-block;
  height: 18px;
  left: 0;
  margin-left: -18px;
  position: absolute;
  transition: border 0.5s ease-in-out;
  width: 18px;
  outline: none !important;
}
.radio label::after {
  -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -ms-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  -webkit-transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  background-color: #797979;
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 10px;
  left: 6px;
  margin-left: -20px;
  position: absolute;
  top: 4px;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  width: 10px;
}
.radio input[type=radio] {
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  outline: none !important;
}
.radio input[type=radio]:disabled + label {
  opacity: 0.65;
}
.radio input[type=radio]:focus + label::before {
  outline-offset: -2px;
  outline: 5px auto -webkit-focus-ring-color;
  outline: thin dotted;
}
.radio input[type=radio]:checked + label::after {
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type=radio]:disabled + label::before {
  cursor: not-allowed;
}

.radio.radio-inline {
  margin-top: 0;
}

.radio.radio-single input {
  position: relative;
  bottom: 9px;
}
.radio.radio-single label {
  height: 17px;
}

.radio-custom input[type=radio] + label::after {
  background-color: #02c0ce;
}
.radio-custom input[type=radio]:checked + label::before {
  border-color: #02c0ce;
}
.radio-custom input[type=radio]:checked + label::after {
  background-color: #02c0ce;
}

.radio-primary input[type=radio] + label::after {
  background-color: #02c0ce;
}
.radio-primary input[type=radio]:checked + label::before {
  border-color: #02c0ce;
}
.radio-primary input[type=radio]:checked + label::after {
  background-color: #02c0ce;
}

.radio-danger input[type=radio] + label::after {
  background-color: #f1556c;
}
.radio-danger input[type=radio]:checked + label::before {
  border-color: #f1556c;
}
.radio-danger input[type=radio]:checked + label::after {
  background-color: #f1556c;
}

.radio-info input[type=radio] + label::after {
  background-color: #4eb7eb;
}
.radio-info input[type=radio]:checked + label::before {
  border-color: #4eb7eb;
}
.radio-info input[type=radio]:checked + label::after {
  background-color: #4eb7eb;
}

.radio-warning input[type=radio] + label::after {
  background-color: #f9bc0b;
}
.radio-warning input[type=radio]:checked + label::before {
  border-color: #f9bc0b;
}
.radio-warning input[type=radio]:checked + label::after {
  background-color: #f9bc0b;
}