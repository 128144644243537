/* You can add global styles to this file, and also import other style files */
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import url('https://fonts.googleapis.com/css?family=Rubik:400,500&display=swap');
@import './assets/css/calendar.css';
@import './assets/css/check-box.css';
@import './assets/css/responsive.css';
@import './assets/css/jquery.steps.css';

html {
  overflow: auto;
}

.img-cover {
  object-fit: cover;
}

.img-container-avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-bold {
  font-weight: bold !important;
  margin-bottom: -2px;
}

.currencyStyle {
  font-size: 18px !important;
  display: contents !important;
}

.navbar.default-layout .navbar-menu-wrapper .navbar-nav li {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.template-searchbox-right {
  display: inline-block;
  float: right;
  margin-top: -20px;
}

[invalidmessage] > p {
  color: #ff5252 !important;
  font-size: 0.8rem;
  padding-top: 0.4rem;
  margin-bottom: 0;
  font-weight: 400 !important;
}

  [invalidmessage] > p:not(:first-child) {
    display: none;
  }

.unread-message {
  background: #edf2fa;
}

input[type='file'] {
  opacity: 0;
}

img {
  object-fit: cover;
}

.modal-body .row > div:nth-last-child(1) .form-group {
  margin-bottom: 0;
}

.modal-header .modal-title {
  font-weight: bold;
}

.checkbox label {
  margin-bottom: 0;
}

.label {
  display: inline-block;
  padding-right: 15px;
  font-weight: 500;
}

.input-group-prepend {
  height: calc(2.25rem + 2px);
}

.no-border {
  border: 0 !important;
}

.thumb-256 {
  height: 256px;
  width: 256px;
}

.mr-15 {
  margin-right: 10px;
}

.project-box .project-members div {
  box-shadow: none;
}

.required{
  margin-left: 2px;
  color: red;
}

.img-thumbnail {
  width: 100%;
  height: 100%;
}
textarea{
  width: 100%;
  border: 1px solid #d9e3e9;
  border-radius: 4px;
  max-width: 100%;
  padding: .469rem .75rem;
  font-size: 14px;
  box-shadow: none;
  transition: all 300ms linear;
}
table.table td, table.table th{
    text-align: left; 
    vertical-align: middle ;
}
.card-body {
  overflow: auto;
}

#datatable_filter input[type=search]
{
  height: 38px;
}

.admin-list-view-scrollable {
  max-height: 500px;
  overflow: hidden;
  min-height: 65px;
}

.font-500 {
  font-weight: 500;
}

.position-abs {
  position: absolute !important;
}

.float-right-right-0 {
  float: right !important;
  right: 0px !important;
}


.dropdown-menu.show{
  cursor: pointer;
}

.project-leader-mark {
  border: 2px solid red;
}

.project-hide-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.description-hide-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis !important;
  word-break: break-all;
  white-space: pre-line;
  height: 2.5rem;
} 

.projectNameDisplay{
  float: right;
  position: absolute;
}

.memnber-hide-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: clip;
}

.fc-past {
  background-color: #eeeeee !important;
  cursor: context-menu;
}

a.fc-more {
  padding: 5px 14px;
    background: #02c0ce;
}

#metting .fc-event-container .fc-event {
  margin:auto;
  background-color: transparent;
  border-radius: 70%;
  border: 1px solid #02c0ce;
  width: min-content;
}

#metting .fc-event-container .fc-event:not(:first-child){
  margin-top: 7px;
}

.enlarged #wrapper .left.side-menu #sidebar-menu ul > li > a span :hover {
  cursor: pointer;
}

.dataTables_wrapper .col-sm-12 {
  padding-left: 0px;
  padding-right: 0px;
}