/*
Template Name: Highdmin - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Email: coderthemes@gmail.com
File: Main Css File
*/

/*
1. Menu (With dark leftbar)
2. Common
3. Helper
4. Bootstrap Reset
5. Waves Effect
6. Card
7. Buttons
8. Ribbons
9. Popovers and Tooltips
10. Checkbox & Radio
11. Alerts
12. Modal
13. Spinners
14. Widgets
15. Tabs
16. Progress
17. Sweet Alert
18. Charts
19. Email
20. Form Elements
21. Form Advanced
22. Form Validation
23. Form Pickers
24. Form Wizard
25. Summernote
26. Wysiwig
27. Form Uploads
28. Tables
29. Maps
30. Calendar
31. Tickets
32. taskboards
33. Projects
34. Companies
35. File Manager
36. Timeline
37. Profile
38. Print
39. FAQ
40. Pricing
41. Account-Pages
42. Search Results
43. Gallery
44. Coming Soon
45. Responsive
*/

@import "variables";
@import "menu_dark";
@import "common";
@import "helper";
@import "bootstrap-reset";
@import "waves";
@import "card";
@import "buttons";
@import "ribbons";
@import "popover-tooltips";
@import "checkbox-radio";
@import "alerts";
@import "modal";
@import "spinners";
@import "widgets";
@import "tabs";
@import "progress";
@import "sweet-alert";
@import "charts";
@import "email";
@import "form-elements";
@import "form-advanced";
@import "form-validation";
@import "form-pickers";
@import "form-wizard";
@import "summernote";
@import "wysiwig";
@import "form-uploads";
@import "tables";
@import "maps";
@import "calendar";
@import "tickets";
@import "taskboard";
@import "projects";
@import "companies";
@import "file-manager";
@import "timeline";
@import "profile";
@import "print";
@import "faq";
@import "pricing";
@import "account-pages";
@import "search-results";
@import "gallery";
@import "coming-soon";
@import "responsive";


