/* =============
  Menu
============= */

/* Metis Menu css */
.metismenu {
  padding: 0;

  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }
}

.nav-second-level li a, .nav-thrid-level li a {
  padding: 8px 20px 8px 10px;
  color: $bg-leftbar-menu-color;
  display: block;
  position: relative;

  &:focus,&:hover  {
    background-color: $bg-leftbar-dark;
    color: lighten($bg-leftbar-menu-color,5%);
  }
}

.nav-second-level > li > a {
  padding-left: 58px;
}

.nav-second-level li.active {
  > a {
    color: $custom;
  }
}

.nav-third-level > li > a {
  padding-left: 68px;
}

.nav-third-level li.active {
  > a {
    color: $bg-leftbar-menu-color;
  }
}


.navbar-custom {
  border-radius: 0;
  margin-bottom: 0;
  padding: 0 15px;
  min-height: 70px;

  .nav-link {
    padding: 0;
    line-height: 70px;
    color: rgba($dark,0.8);
  }

  .dropdown-toggle {
    &:after {
      content: initial;
    }
  }

  .menu-left {
    overflow: hidden;
  }

  .topbar-right-menu {
    li {
      float: left;
    }
  }
}

.logo {
  color: $white;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
  font-family: $font-secondary;

  span {
    span {
      color: $custom;
    }
  }
}

.user-box {
  padding: 90px 25px 25px 25px;

  .user-img {
    position: relative;
    height: 48px;
    width: 48px;
    margin-bottom: 15px;
  }

  h5{
    margin-bottom: 2px;
    a{
      color: $bg-leftbar-menu-color !important;
    }

  }
}


.side-menu {
  width: $leftbar-width;
  z-index: 10;
  background: $bg-leftbar-dark;
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  position: fixed;
  top: 0;

  .topbar-left {
    background-color: $bg-leftbar-dark;
    height: 70px;
    padding-left: 25px;
    position: fixed;
    z-index: 1;
    width: $leftbar-width;
    top: 0;

    .logo {
      line-height: 70px;

      i {
        display: none;
      }
    }
  }
}

.enlarged .side-menu {
  position: absolute;
}

.content-page {
  margin-left: $leftbar-width;
  overflow: hidden;
  padding: 25px 25px 15px 25px;
  min-height: 80vh;

  .content {
    margin-top: 20px;
  }

}

.button-menu-mobile {
  border: none;
  color: $dark;
  display: inline-block;
  height: 70px;
  width: 60px;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;
}

.button-menu-mobile.disable-btn {
  display: none;
}


#sidebar-menu > ul > li > a {
  color: $bg-leftbar-menu-color;
  display: block;
  padding: 12px 20px;
  margin: 2px 0;
  font-size: 15px;
  position: relative;

  &:hover,&:focus,&:active {
    color: $custom;
    text-decoration: none;
  }
}

#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

#sidebar-menu {

  .menu-arrow {
    -webkit-transition: -webkit-transform .15s;
    -o-transition: -o-transform .15s;
    transition: transform .15s;
    position: absolute;
    right: 20px;
    display: inline-block;
    font-family: 'Material Design Icons';
    text-rendering: auto;
    line-height: 28px;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);

    &:before {
      content: "\F142";
    }
  }
  .badge,.label {
    margin-top: 4px;
  }

  li.active {
    .menu-arrow {
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  ul {
    li {
      a{
        i{
          display: inline-block;
          font-size: 18px;
          line-height: 17px;
          margin: 0 10px 0 3px;
          text-align: center;
          vertical-align: middle;
          width: 20px;
        }

        .drop-arrow {
          float: right;

          i{
            margin-right: 0;
          }
        }
      }
    }
  }
}

#sidebar-menu > ul > li > a.active {
  color: cyan;
}

.menu-title {
  padding: 12px 20px;
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  font-size: 11px;
  text-transform: uppercase;
  color: $muted;
  font-family: $font-secondary;
}

.enlarged {
  .slimScrollDiv,.slimscroll-menu {
    overflow: inherit !important;
  }
  .user-box {
    display: none;
  }
  .slimScrollBar {
    visibility: hidden;
  }
}
.enlarged #wrapper {
  #sidebar-menu {
    .menu-title, .menu-arrow, .label, .badge {
      display: none !important;
    }
    .collapse.in {
      display: none !important;
    }
    .nav.collapse {
      height: inherit !important;
    }
    ul {
      ul {
        margin-top: -2px;
        padding-bottom: 5px;
        padding-top: 5px;
        z-index: 9999;
        background-color: $bg-leftbar-dark;
      }
    }
  }

  .left.side-menu {
    width: 70px;
    z-index: 5;

    #sidebar-menu > ul > li > a {
      padding: 15px 20px;
      min-height: 56px;
      &:hover,&:active,&:focus {
        color: #02c0ce;
        background-color: transparent;
      }

      i {
        font-size: 18px;
        margin-right: 20px;
      }
    }
    .label {
        position: absolute;
        top: 5px;
        left: 35px;
        text-indent: 0;
        display: block;
        padding: .2em .6em .3em;
    }

    #sidebar-menu {
      ul > li {
        position: relative;
        white-space: nowrap;
        &:hover > a {
          position: relative;
          width: 260px;
          color: $dark;
          background-color: $light;
        }
        &:hover > ul {
          display: block;
          left: 70px;
          position: absolute;
          width: 190px;
          height: auto !important;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.02), 0 1px 0 0 rgba(0, 0, 0, 0.02);

          a {
            box-shadow: none;
            padding: 8px 20px;
            position: relative;
            width: 190px;
            z-index: 6;

            &:hover {
              color: $dark;
            }
          }
        }
        &:hover {
          a {
            span {
              display: inline;
            }
          }
        }
      }

      ul {
        ul {
          li {
            &:hover > ul {
              display: block;
              left: 190px;
              margin-top: -36px;
              position: absolute;
              width: 190px;
            }
          }
          li > a {
            span.pull-right {
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              position: absolute;
              right: 20px;
              top: 12px;
              transform: rotate(270deg);
            }
          }
          li.active {
            a{
              color: $dark;
            }
          }
        }
      }
      ul > li > a {
        span {
          display: none;
          padding-left: 10px;
        }
      }
    }
    .user-details {
      display: none;
    }
  }
  .content-page {
    margin-left: 70px;
  }
  .footer {
    left: 70px;
  }
  .topbar-left {
    width: 70px;
    position: relative;
    padding-left: 0;
    text-align: center;

    .logo {
      span {
        display: none;
        opacity: 0;
      }
      i {
        display: block;
        line-height: 70px;
        color: $custom;
      }
    }
  }
  #sidebar-menu > ul > li {
    &:hover > a.open {
      :after {
        display: none;
      }
    }
    &:hover > a.active {
      :after {
        display: none;
      }
    }
  }
}

#wrapper.right-bar-enabled {
  .right-bar {
    right: 0;
  }
}


/* Search */
.app-search {
  position: relative;
  padding-top: 18px;
  /*padding-right: 20px;*/

  button {
    position: absolute;
    top: 16px;
    left: 160px;
    display: block;
    height: 34px;
    line-height: 34px;
    width: 34px;
    text-align: center;
    color: rgba($dark, 0.5);
    background-color: transparent;
    border: none;

    &:hover {
      color: $dark;
    }
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $light;
    font-size: 13px;
    height: 34px;
    color: $dark;
    padding-left: 20px;
    padding-right: 40px;
    background: $light;
    box-shadow: none;
    border-radius: 30px;
    width: 200px;
  }
}






/* Body min-height set */
body.enlarged {
  min-height: 1200px;
}

body.enlarged.bg-transparent {
  min-height: 500px;
}


/* Footer */
.footer {
  border-top: 1px solid rgba($muted,0.2);
  bottom: 0;
  text-align:left !important;
  padding: 19px 30px 20px;
  position: absolute;
  right: 0;
  color: $muted;
  left: $leftbar-width;
}


/* Notification */
.notification-wrapper {
  max-height: 190px;
}

.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: $white;
    padding: 7px 20px;
  }

  .noti-icon {
    font-size: 20px;
    padding: 0 15px;
    vertical-align: middle;
  }
  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 8px;
  }
  .notify-item {
    padding: 10px 20px;

    .notify-icon {
      float: left;
      height: 24px;
      width: 24px;
      line-height: 24px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }
    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      b {
        font-weight: 500;
      }
      small {
        display: block;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }
    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 20px;
    }
  }
  .notify-all {
  }
  .profile-dropdown {
    .notify-item {
      padding: 7px 20px;
    }
  }
}

.profile-dropdown {
  width: 170px;
  i {
    vertical-align: middle;
    margin-right: 5px;
  }
  span {
    vertical-align: middle;
  }
}

.nav-user {
  padding: 0 12px !important;

  img {
    height:32px;
    width: 32px;
  }
}




/* Small Menu */
.side-menu-sm {
  width: $leftbar-width-sm;
  text-align: center;

  #sidebar-menu>ul>li>a>i {
    display: block;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    margin: 0;
  }

  #sidebar-menu ul ul a {
    padding: 10px 20px 10px 20px;
  }

  .user-box .user-img {
    margin: 0 auto 15px auto;
  }

  .topbar-left {
    padding-left: 0;
    width: $leftbar-width-sm;
  }

  .menu-arrow,.badge {
    display: none !important;
  }

}

.side-menu-sm + .content-page {
  margin-left: $leftbar-width-sm;

  .footer {
    left: $leftbar-width-sm;
  }

}

.enlarged #wrapper .side-menu-sm {
  text-align: left;
}

.enlarged #wrapper .side-menu-sm ul li a i {
  display: inline-block;
  font-size: 18px;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 15px;
  vertical-align: middle;
  width: 20px;
}






@media (max-width: 1024px) {
  .button-menu-mobile.disable-btn {
    display: inline-block;
  }
  .content-page {
    padding: 0;

    .content {
      padding-top: 70px;
    }
  }
  .navbar-custom {
    background-color: $white;
    position: fixed;
    left: 0;
    right: 0;
    box-shadow: $shadow;
    z-index: 999;
  }
  .page-title-box {
    .page-title {
      font-size: 16px;
      margin-bottom: 6px;
      margin-top: 24px;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .breadcrumb {
      display: none;
    }
  }
}



@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .topbar-left {
    width: 70px !important;

    span {
      display: none;
    }
    i {
      display: block !important;
      line-height: 70px;
    }
  }

  .side-menu {
    box-shadow: $shadow;
  }

  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    margin-left: 0 !important;
  }
  .enlarged .left.side-menu {
    margin-left: -70px;
  }
  .footer {
    left: 0 !important;
  }
}

@media (max-width: 767px) {

  .dropdown-lg {
    width: 200px !important;
  }
}

@media (min-width: 480px){
  .navbar-custom{
    .notification-list {
      .dropdown-menu.dropdown-menu-right {
        transform: none !important;
        top: 100% !important;
        right: 0 !important;
        left: auto !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .side-menu {
    z-index: 10 !important;
  }

  .search-bar {
    display: none !important;
  }
}

@media (max-width: 420px) {
  .hide-phone {
    display: none !important;
  }
}


@media (max-width: 419px) {
  .hidden-xxs {
    display: none;
  }

  .logo {
    .icon-c-logo {
      display: inline-block !important;
      line-height: 58px !important;
    }
    span {
      display: none !important;
    }
  }

  .content-page {
    margin-left: 70px;
  }
  .forced {
    .side-menu.left {
      box-shadow: 0 12px 12px rgba(0, 0, 0, 0.1);
      //position: absolute;
    }
  }
  .enlarged {
    .side-menu.left {
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) !important;
    }
  }
  .page-title {
    font-size: 15px;
    max-width: 250px;
    white-space: nowrap;
  }
  .navbar-default {
    padding: 0;
    .navbar-left {
      padding-left: 0 !important;

      li {
        padding: 0 5px;
      }
    }
  }
  .topbar-left {
    display: none;
  }

  .editable-responsive {
    overflow-x: auto;
  }

  .page-title-box .breadcrumb {
    display: none;
  }

  .navbar-nav .open .dropdown-menu {
    margin-right: -20px;
  }
  .user-list .user-list-item .icon,.user-list .user-list-item .avatar {
    display: none;
  }
  .user-list .user-list-item .user-desc {
    margin-left: 0;
  }

}


