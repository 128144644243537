
/* =============
   Calendar
============= */
.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 30px;
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin-bottom: 5px;
  margin-top: 15px;
}
.fc-toolbar h2 {
  font-size: 20px;
  line-height: 30px;
  text-transform: uppercase;
}

.fc-day {
  background: #ffffff;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  background: #e3eaef;
  font-size: 13px;
  line-height: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}

.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
  border-color: #e3eaef;
}

.fc-button {
  background: #e3eaef;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none;
  border-radius: 3px;
  margin: 0 3px;
  padding: 6px 12px;
  height: auto;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px;
}

.fc-state-hover {
  background: #f3f3f3;
}

.fc-state-highlight {
  background: #f0f0f0;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: #02c0ce;
  color: #ffffff;
  text-shadow: none;
}

.fc-cell-overlay {
  background: #f0f0f0;
}

.fc-unthemed .fc-today {
  background: #ffffff;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 13px;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 10px 0;
  padding: 8px 10px;
  color: #ffffff;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
}
.fc-basic-view td.fc-day-number {
  padding-right: 8px;
}
.fc-basic-view .fc-content {
  color: #ffffff;
}

.fc-time-grid-event .fc-content {
  color: #ffffff;
}
.makeup {
  background-color: #8d4083;
}
.un-certain {
  background-color: #0f9d58;
}
.certain {
  background-color: #fef5e1;
}
.company-specified {
  background-color: #308ee0;
}
.tickets-list a {
  white-space: nowrap;
}
.tickets-list img {
  height: 32px;
  width: 32px;
}
.tickets-list td {
  vertical-align: middle;
}
.fc-button:disabled,
.fc-button-active.fc-button-active.fc-button-active.fc-button-active {
  background-color: #02c0ce;
  color: #ffffff;
  text-shadow: none;
}
.fc-button:active,
.fc-button:focus {
  box-shadow: unset !important;
  border: 0 !important;
  outline: 0 !important;
}
.fc-toolbar > * > :not(:first-child) {
  margin-left: 0.25em;
}
.fc-button:not(:disabled):hover {
  background-color: #f3f3f3;
  color: unset;
}
.fc-day-top .fc-day-number {
  padding-right: 8px;
}
.fc-day-header.fc-widget-header span {
  font-weight: 500;
}
.fc table {
  line-height: 25px;
}
.fc-view-container {
  cursor: pointer;
}
.fc-unthemed td.fc-today {
  background-color: #e8fde7;
}
.fc-content {
  color: #f0f0f0;
  font-weight: bold;
}
.certain .fc-content {
  color: #565656;
}
.fc-list-table .fc-content {
  color: inherit;
  font-weight: bold;
  background-color:white;
  padding-left: 15px;
}

.fc-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.fullday,
.fullday-day.fullday-day.fullday-day.fullday-day.fullday-day {
  background-color: #3f51b6 !important;
}

.afternoon {
  background-color: #5677fc;
}

.morning {
  background-color: #01bcd6;
}

.fc-event-container .fc-day-grid-event:hover {
  cursor: pointer;
}

.morning-day.morning-day.morning-day.morning-day.morning-day.morning-day {
  background: linear-gradient(#01bcd6, #01bcd6);
  background-position-y: top;
  background-size: 100% 50%;
  background-repeat: no-repeat;
}
.afternoon-day.afternoon-day.afternoon-day.afternoon-day.afternoon-day {
  background: linear-gradient(#5677fc, #5677fc);
  background-position-y: bottom;
  background-size: 100% 50%;
  background-repeat: no-repeat;
}
.urgent-event,
.approve-event,
.waitting-event,
.reject-event,
.waiting-delete-event {
  position: relative;
}
.reject-event::after,
.reject-event-status {
  background-color: #e51c23;
}
.urgent-event::after,
.urgent-event-status {
  background-color: #607d8d;
}
.approve-event::after,
.approve-event-status {
  background-color: #259b24;
}
.waitting-event::after,
.waitting-event-status {
  background-color: #ffc107;
}
.waiting-delete-event::after,
.waiting-delete-event-status {
  background-color: #ff679b;
}
.urgent-event::after,
.approve-event::after,
.waitting-event::after,
.reject-event::after,
.waiting-delete-event::after {
  content: "";
  height: 7px;
  width: 7px;
  border: 1px solid #9e9e9e;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: 0;
}
.fc-list-item.urgent-event::after,
.fc-list-item.waitting-event::after,
.fc-list-item.approve-event::after,
.fc-list-item.waiting-delete-event::after {
  content: none;
}
.fc-list-item-title.fc-list-item-title.fc-list-item-title.fc-list-item-title,
.fc-list-heading .fc-widget-header.fc-widget-header.fc-widget-header {
  padding: 0 10px;
  line-height: 30px;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end {
  margin: 12px 5px 5px;
}
.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.x2 {
  margin: 12px 5px 5px;
  background-color: royalblue;
}
.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.x3 {
  margin: 12px 5px 5px;
  background-color: red;
}
.fc-list-item.fc-list-item.fc-list-item {
  background-color: #e3eaef;
}